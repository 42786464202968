import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { MenuItem, Menu } from "semantic-ui-react";
import {
  getAniosDisponiblesDerivaciones,
  getEstadoConveniosPorAnio,
  getEstadoConveniosPorMesYAnio,
} from "./serviceOrion";
import { BACKGROUND_COLOR_PLUGIN } from "./../shared/utils";
import TituloYDescarga from "../shared/components/TituloYDescarga";

ChartJS.register(ArcElement, Tooltip, Legend);

const DerivacionesOrionEstado = () => {
  const CHART_ID_PIE = "derivaciones_orion_estado_pie";
  const CHART_ID_BAR = "derivaciones_orion_estado_bar";
  const CHART_TITLE = "Estado de los convenios";

  const [dataSet, setDataSet] = useState(null);
  const [dataSetBar, setDataSetBar] = useState(null);
  const [activeItem, setActiveItem] = useState(new Date().getFullYear());
  const [aniosDisponibles, setAniosDisponibles] = useState([
    new Date().getFullYear(),
  ]);
  const handleItemClick = (e, { name }) => setActiveItem(name);

  useEffect(() => {
    async function fetchData() {
      setAniosDisponibles(await getAniosDisponiblesDerivaciones());
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setDataSet(await getEstadoConveniosPorAnio(activeItem));
      setDataSetBar(await getEstadoConveniosPorMesYAnio(activeItem));
    }

    fetchData();
  }, [activeItem]);

  return (
    <>
      <TituloYDescarga
        titulo={CHART_TITLE}
        idsCharts={[CHART_ID_PIE, CHART_ID_BAR]}
        chartsNames={[CHART_ID_PIE, CHART_ID_BAR]}
      />
      {dataSet && dataSetBar ? (
        <div>
          <Menu attached="top" tabular>
            {aniosDisponibles &&
              aniosDisponibles.map((anio) => (
                <MenuItem
                  name={anio}
                  active={activeItem === anio}
                  onClick={handleItemClick}
                />
              ))}
          </Menu>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Pie
                id={CHART_ID_PIE}
                plugins={[BACKGROUND_COLOR_PLUGIN]}
                width={300}
                data={dataSet}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Bar
                id={CHART_ID_BAR}
                plugins={[BACKGROUND_COLOR_PLUGIN]}
                height={100}
                data={dataSetBar}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>Cargando...</div>
      )}
    </>
  );
};
export default DerivacionesOrionEstado;
