import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { getServiciosPorAnio } from "./serviceLizen";
import { BACKGROUND_COLOR_PLUGIN } from "./../shared/utils";
import TituloYDescarga from "../shared/components/TituloYDescarga";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const ServiciosLizenTiempo = () => {
  const CHART_ID = "servicios_lizen_tiempo";
  const CHART_TITLE = "Cantidad de servicios por año";
  const [dataSet, setDataSet] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setDataSet(await getServiciosPorAnio());
    }
    fetchData();
  }, []);

  const options = {
    plugins: {
      legend: true,
    },
  };

  return (
    <>
      <TituloYDescarga
        titulo={CHART_TITLE}
        idsCharts={[CHART_ID]}
        chartsNames={[CHART_ID]}
      />
      {dataSet ? (
        <Line
          id={CHART_ID}
          plugins={[BACKGROUND_COLOR_PLUGIN]}
          data={dataSet}
          height={50}
          options={options}
        ></Line>
      ) : (
        <div>Cargando...</div>
      )}
    </>
  );
};

export default ServiciosLizenTiempo;
