import { Grid } from "semantic-ui-react";
import ServiciosLizenTiempo from "./Lizen/SeviciosLizenTiempo";
import ServiciosLizenEstado from "./Lizen/ServiciosLizenEstado";
import ServiciosLizenConvenioBar from "./Lizen/ServiciosLizenConvenioBar";
import DerivacionesOrionTiempo from "./Orion/DerivacionesOrionTiempo";
import DerivacionesOrionConvenioBar from "./Orion/DerivacionesOrionConvenio";
import DerivacionesOrionEstado from "./Orion/DerivacionesOrionEstado";
import React, { useState } from "react";
import { MenuItem, Menu, Segment } from "semantic-ui-react";

const Dashboard = () => {
  const [activeItem, setActiveItem] = useState("lizen");

  const handleItemClick = (e, { name }) => setActiveItem(name);

  return (
    <div>
      <Menu attached="top" tabular>
        <MenuItem
          name="lizen"
          active={activeItem === "lizen"}
          onClick={handleItemClick}
        />
        <MenuItem
          name="orion"
          active={activeItem === "orion"}
          onClick={handleItemClick}
        />
      </Menu>
      <Segment attached="bottom">
        {activeItem === "lizen" && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <ServiciosLizenTiempo />
              </Grid.Column>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <ServiciosLizenConvenioBar />
              </Grid.Column>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <ServiciosLizenEstado />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {activeItem === "orion" && (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <DerivacionesOrionTiempo />
              </Grid.Column>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <DerivacionesOrionConvenioBar />
              </Grid.Column>
              <Grid.Column width={16} style={{ margin: "10px" }}>
                <DerivacionesOrionEstado />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Segment>
    </div>
  );
};

export default Dashboard;
