import { Icon } from "semantic-ui-react";

const TituloYDescarga = ({ titulo, idsCharts, chartsNames }) => {
  const handlePrintChart = (idsCharts, chartsNames) => {
    idsCharts.forEach((idChart, index) => {
      const canvas = document.getElementById(idChart);
      const imagen = canvas.toDataURL("image/png");

      const enlace = document.createElement("a");
      enlace.href = imagen;
      enlace.download = `${chartsNames[index]}.png`;
      enlace.click();
    });
  };

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <h4>{titulo}</h4>
      <Icon
        title="Descargar grafico"
        name="print"
        onClick={() => handlePrintChart(idsCharts, chartsNames)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default TituloYDescarga;
