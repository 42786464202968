import { BASE_API } from "../../../../../utils/constantes";
import { generarDataSetBar, generarDataSetBarEstados, generarDataSetLine, generarDataSetPie } from "./../shared/utils";

export async function getServiciosPorConvenio() {
    let data = await fetch(`${BASE_API}/api/dashboard/servicio_convenio_anio/`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
    }).then((response) => response.json()).then((data) => {
        return generarDataSetBar(data);
    })
    return data;
}

export async function getServiciosPorAnio() {
    let data = await fetch(`${BASE_API}/api/dashboard/servicio_anio/`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
    }).then((response) => response.json()).then((data) => {
        return generarDataSetLine(data)
    })
    return data;
}

export async function getAniosDisponibles() {
    let anios = await fetch(
      `${BASE_API}/api/dashboard/servicio_estado_anio/?anios_disponibles=true`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return(data.map((item) => item.anio));
      });
      console.log(anios)
      return anios;
  }

export async function getEstadoServiciosPorAnio(anio) {
    let data = await fetch(`${BASE_API}/api/dashboard/servicio_estado_anio/?anio=${anio}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return(generarDataSetPie(data));
      });

      return data;
  }

export async function getEstadoServiciosPorMesYAnio(anio) {
    let data = await fetch(`${BASE_API}/api/dashboard/servicio_estado_anio_mes/?anio=${anio}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return(generarDataSetBarEstados(data));
        });
        return data;
  }