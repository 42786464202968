import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  BarElement,
} from "chart.js";
import { useEffect, useState } from "react";
import { getDerivacionesPorConvenio } from "./serviceOrion";
import { BACKGROUND_COLOR_PLUGIN } from "./../shared/utils";
import TituloYDescarga from "../shared/components/TituloYDescarga";

ChartJS.register(
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const DerivacionesOrionConvenioBar = () => {
  const CHART_ID = "derivaciones_orion_convenio";
  const CHART_TITLE = "Cantidad de derivaciones por convenio";

  const [dataSet, setDataSet] = useState(null);
  useEffect(() => {
    async function fetchData() {
      setDataSet(await getDerivacionesPorConvenio());
    }
    fetchData();
  }, []);

  const options = {
    plugins: {
      legend: true,
    },
  };
  return (
    <>
      <TituloYDescarga
        titulo={CHART_TITLE}
        idsCharts={[CHART_ID]}
        chartsNames={[CHART_ID]}
      />
      {dataSet ? (
        <Bar
          id={CHART_ID}
          plugins={[BACKGROUND_COLOR_PLUGIN]}
          data={dataSet}
          height={50}
          options={options}
        ></Bar>
      ) : (
        <div>Cargando...</div>
      )}
    </>
  );
};

export default DerivacionesOrionConvenioBar;
